import React, { useState, useEffect, useMemo } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function GallerySlider({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const specificFirstImage = "/imagenes-antonia/gallery.jpg";
  const reorderedImages = useMemo(() => 
    [specificFirstImage, ...images.filter(img => img !== specificFirstImage)],
    [images]
  );

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = reorderedImages.slice(0, 5).map(src => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      await Promise.all(imagePromises);
      setImagesLoaded(true);
    };

    preloadImages();
  }, [reorderedImages]);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextSlide();
    }, 3000);

    return () => clearInterval(timer);
  }, [reorderedImages]);

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : reorderedImages.length - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < reorderedImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="relative h-full w-full overflow-hidden">
      {!imagesLoaded && (
        <div className="flex items-center justify-center h-full">
          <p className="text-2xl">Loading gallery...</p>
        </div>
      )}
      {imagesLoaded && (
        <>
          <div className="relative h-full w-full">
            {reorderedImages.map((image, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-500 ${
                  index === currentIndex ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <img
                  src={image}
                  alt={`Gallery image ${index + 1}`}
                  className="w-full h-full object-cover object-center"
                  loading={index === currentIndex ? "eager" : "lazy"}
                />
              </div>
            ))}
          </div>
          <button
            onClick={handlePrevSlide}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white opacity-50 hover:opacity-100 transition-opacity z-10"
          >
            <FaChevronLeft size={24} />
          </button>
          <button
            onClick={handleNextSlide}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white opacity-50 hover:opacity-100 transition-opacity z-10"
          >
            <FaChevronRight size={24} />
          </button>
        </>
      )}
    </div>
  );
}

export default GallerySlider;
