import React from 'react';
import ContactFrame from '../components/ContactFrame';
import { useMediaQuery } from 'react-responsive';

function InformationPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <ContactFrame>
      <div className="flex flex-col min-h-full w-full py-8 px-4 overflow-y-auto">
        <div className={`${isMobile ? 'pb-6' : 'absolute top-20 left-0'}`}>
          <img 
            src="/imagenes-antonia/logo-AG.png" 
            alt="Antonia Garcia De Vicente" 
            className={`${isMobile ? 'w-32 mt-64 -ml-5' : 'w-32 sm:w-40 md:w-48 lg:w-56 xl:w-64 max-w-xs mt-4 sm:mt-8 md:mt-16 lg:mt-24'}`}
          />
        </div>

        <div className="flex-grow flex items-center justify-center">
          <div className="bg-transparent bg-opacity-80 p-4 sm:p-8 max-w-md w-full mx-auto">
            <h2 className="text-3xl sm:text-3xl font-serif text-left mb-6 text-white">¿Hablemos?</h2>
            <form className="space-y-4">
              <input type="text" placeholder="Nombre:" className="font-sans font-light w-full p-2 border border-gray-300 bg-[#F9F5EA] text-gray-800" />
              <input type="email" placeholder="Mail:" className="font-sans font-light w-full p-2 border border-gray-300 bg-[#F9F5EA] text-gray-800" />
              <textarea placeholder="Mensaje:" className="font-sans font-light w-full p-2 border border-gray-300 bg-[#F9F5EA] text-gray-800 h-32"></textarea>
              <button type="submit" className="font-sans font-light w-full bg-[#F9F5EA] text-gray-800 p-2 hover:bg-[#F9F5EA] hover:text-gray-800 transition-colors">Enviar</button>
            </form>
            <div className="mt-6">
              <h3 className="text-3xl sm:text-3xl font-serif mb-2 text-white text-left">Escríbenos</h3>
              <div className="bg-[#F9F5EA] p-2 flex items-center justify-center">
                <span className="text-black font-sans font-light text-center text-sm sm:text-base">contacto@antoniagarcia.cl / +569 9 63153 45</span>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="absolute top-4 right-4 text-white text-xl">CONTACTO</div>
        )}
        {isMobile && <div className="h-16"></div>} {/* Add extra space at the bottom for mobile */}
      </div>
    </ContactFrame>
  );
}

export default InformationPage;
