import React from 'react';

function ServiceContainer({ title, description, isMobile }) {
  return (
    <div className={`service-container flex flex-col bg-[#F9F5EA] bg-opacity-70 shadow-md h-full w-full max-w-[280px] ${isMobile ? 'aspect-[3/4]' : 'aspect-[4/5]'} p-3 sm:p-4 md:p-5 lg:p-5 custom-md:max-w-[260px]`}>
      <h2 className={`sans-serif font-medium ${isMobile ? 'text-[12px]' : 'text-[10px] xs:text-[11px] sm:text-[12px] md:text-[14px] lg:text-[18px] custom-md:text-[15px]'} text-gray-800 text-center mb-1 xs:mb-1.5 sm:mb-2 md:mb-2.5 lg:mb-3`}>
        {title}
      </h2>
      <div className="flex flex-col flex-grow">
        <div className="flex-grow flex flex-col justify-start">
          <p className={`font-sans font-normal ${isMobile ? 'text-[10px]' : 'text-[8px] xs:text-[9px] sm:text-[10px] md:text-[11px] lg:text-[12px] xl:text-[15px]'} text-gray-800 leading-[1.4] xs:leading-[1.5] sm:leading-[1.6] md:leading-[1.7] lg:leading-[1.8] text-center overflow-y-auto pt-2 sm:pt-3 md:pt-4`}>
            {description}
          </p>
        </div>
        <div className="mt-auto flex justify-center">
          <button className={`text-gray-800 ${isMobile ? 'text-[12px]' : 'text-[10px] xs:text-[11px] sm:text-[12px] md:text-[13px] lg:text-[14px] xl:text-[20px]'} font-serif hover:underline mt-2 sm:mt-2.5 md:mt-3 lg:mt-4`}>
            ¿Hablemos?
          </button>
        </div>
      </div>
    </div>
  );
}

export default ServiceContainer;
