import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import InformationPage from './pages/InformationPage';
import ServicesPage from './pages/ServicesPage';
import GalleryPage from './pages/GalleryPage';


function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="main-content pt-16 md:pt-20">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/info" element={<InformationPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/contact" element={<InformationPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}
export default App;
