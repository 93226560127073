import React from 'react';
import ServiceContainer from './ServiceContainer';

function MobileServiceView({ services }) {
  return (
    <div className="flex flex-col space-y-6 pb-24 px-4 min-h-screen">
      {services.map((service, index) => (
        <div key={index} className="w-full max-w-[280px] mx-auto bg-[#F9F5EA] bg-opacity-70 shadow-md p-4">
          <h2 className="sans-serif font-medium text-[16px] text-gray-800 text-center mb-2">
            {service.title}
          </h2>
          <p className="font-sans font-normal text-[12px] text-gray-800 leading-[1.6] text-center mb-4">
            {service.description}
          </p>
          <div className="flex justify-center">
            <button className="text-gray-800 text-[14px] font-serif hover:underline">
              ¿Hablemos?
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MobileServiceView;