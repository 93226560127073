import React from 'react';
import { useMediaQuery } from 'react-responsive';
import InformationFrame from '../components/InformationFrame';
import ServiceContainer from '../components/Services/ServiceContainer';
import MobileServiceView from '../components/Services/MobileServiceView';

function ServicesPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const services = [
    {
      title: "MATRIMONIO LOUNGE",
      description: "Respetamos los códigos tradicionales, pero con un twist. Reeditamos la puesta en escena de un matrimonio con mesones de sabores únicos y a la medida de los novios. Nos gusta la aventura, nos gustan las ideas frescas y llevar el protocolo a una nueva dimensión.",
      image: "/images/matrimonio-lounge.jpg",
    },
    {
      title: "BRAND EXPERIENCE",
      description: "La vanguardia y los sabores de una nueva cocina se fusionan en una experiencia provocativa. Queremos despertar los sentidos, queremos que, a través de la comida y de una puesta en escena rupturista, tu marca sea inolvidable.",
      image: "/images/brand-experience.jpg",
    },
    {
      title: "MODERN CATERING",
      description: "No tememos incorporar nuevos ingredientes, de hecho, eso es lo que nos motiva: los nuevos sabores y nuevas sensaciones en torno a ellos. Cada uno de nuestros eventos es una propuesta sensorial que no se repite.\n\nPorque cada quien es diferente, nosotros celebramos esas diferencias.",
      image: "/images/modern-catering.jpg",
    },
  ];

  return (
    <InformationFrame>
      <div className={`container mx-auto ${isMobile ? 'px-0' : 'px-4'} py-8 h-full flex flex-col ${isMobile ? '' : ''}`}>
        <div className={`${isMobile ? 'mb-10 mt-14' : 'absolute top-20 left-0'}`}>
          <img 
            src="/imagenes-antonia/logo-AG.png" 
            alt="Antonia Garcia De Vicente" 
            className={`${isMobile ? 'w-28' : 'w-32 sm:w-40 md:w-48 lg:w-56 xl:w-64 max-w-xs mt-4 sm:mt-8 md:mt-16 lg:mt-24'}`}
          />
        </div>
        <div className={`flex-grow flex ${isMobile ? 'flex-col' : 'items-center justify-end'}`}>
          {isMobile ? (
            <MobileServiceView services={services} />
          ) : (
            <div className="w-full h-auto md:w-11/12 xl:w-5/6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8 overflow-y-auto py-8 sm:py-12 md:py-16 pr-4 sm:pr-8 md:pr-12">
              {services.map((service, index) => (
                <div key={index} className="service-wrapper">
                  <ServiceContainer {...service} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </InformationFrame>
  );
}

export default ServicesPage;
