import React from 'react';
import { useMediaQuery } from 'react-responsive';
function HomeContent() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div className="relative h-screen overflow-hidden">
      <img 
        src="/imagenes-antonia/inicio/home.jpg" 
        alt="Celebración" 
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black bg-opacity-30" />
      <div className="absolute inset-0 flex flex-col justify-between p-4 sm:p-6 md:p-8">
        <div className="flex justify-between items-start">
        <div className={`${isMobile ? 'fixed top-40 left-0 pr-4 z-10' : 'absolute top-0 left-0'}`}>
          <img 
            src="/imagenes-antonia/logo-AG.png" 
            alt="Antonia Garcia De Vicente" 
            className={`${isMobile ? 'w-32 h-auto' : 'w-32 sm:w-40 md:w-48 lg:w-56 xl:w-64 max-w-xs mt-4 sm:mt-8 md:mt-16 lg:mt-24'}`}
          />
        </div>
        </div>
        <div className="absolute inset-0 flex items-center justify-end">
          <div className="text-white text-right pr-4 sm:pr-6 md:pr-8 lg:pr-12 mt-16 sm:mt-20 md:mt-24 lg:mt-32">
            <div className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-serif leading-relaxed">
              <p>La celebración</p>
              <p>a tu medida,</p>
              <p>como una</p>
              <p>experiencia</p>
              <p>sensorial única</p>
              <p>e irrepetible</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeContent;
