import React from 'react';
import FloralFrame from './InformationFrame';

function ContactFrame({ children }) {
  return (
    <div className="min-h-screen h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: 'url("/imagenes-antonia/contact/contacto.jpg")' }}>
      <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
        {children}
      </div>
    </div>
  );
}

export default ContactFrame;