import React, { useState, useEffect } from 'react';
import GallerySlider from '../components/Gallery/GallerySlider';

function GalleryPage() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch('/gallery-images.json')
      .then(response => response.json())
      .then(data => setImages(data))
      .catch(error => console.error('Error loading gallery images:', error));
  }, []);

  return (
    <div className="h-screen w-full">
      <GallerySlider images={images} />
    </div>
  );
}

export default GalleryPage;
