import React from 'react';
import HomeContent from '../components/Home/HomeContent';

function HomePage() {
  return (
    <div className="h-full">
      <HomeContent />
    </div>
  );
}

export default HomePage;