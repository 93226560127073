import React from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <header className="navbar">
      <div className="navbar-background"></div>
      <nav className="navbar-content pr-10 sm:pr-12 md:pr-16 lg:pr-20 py-4 sm:py-5 md:py-5 lg:py-6">
        <NavLink to="/" className={({ isActive }) => `navbar-link font-sans ${isActive ? 'font-bold' : 'font-light'} mb-2`} end>INICIO</NavLink>
        <NavLink to="/services" className={({ isActive }) => `navbar-link font-sans ${isActive ? 'font-bold' : 'font-light'} mb-2`}>SERVICIOS</NavLink>
        <NavLink to="/gallery" className={({ isActive }) => `navbar-link font-sans ${isActive ? 'font-bold' : 'font-light'} mb-2`}>GALERÍA</NavLink>
        <NavLink to="/contact" className={({ isActive }) => `navbar-link font-sans ${isActive ? 'font-bold' : 'font-light'}`}>CONTACTO</NavLink>
      </nav>
    </header>
  );
}

export default Navbar;
